import { amgApi } from '@/service/axios'

class MigrationsService {
  // client-migration
  async searchClientMigration(params) {
    const { data } = await amgApi.post('/administration/migrations/client-migration', params)
    return data
  }

  // delete-account
  async deleteAccount(params) {
    const data = await amgApi.post('/administration/migrations/delete-account', params)
    return data
  }

  async searchClient(params) {
    const data = await amgApi.post('/administration/migrations/search-client', params)
    return data.data
  }

  async programs() {
    const data = await amgApi.get("/commons/programs");
    return data;
  }

  async usersPrograms(params) {
    const data = await amgApi.post("/commons/users-programs", params);
    return data.data;
  }
}

export default new MigrationsService()
