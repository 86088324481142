<template>
  <div>
    <div>
      <header-slot>
        <template #actions>
          <b-row class="justify-content-end mr-1">
            <b-button variant="primary" @click="openCreateMigration">
              <feather-icon icon="PlusIcon" />
              CREATE
            </b-button>
          </b-row>
        </template>
      </header-slot>
      <div>
        <filter-slot
          :filter="filters"
          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reload="$refs.refMigrationsList.refresh()"
        >
          <template #table>
            <b-table
              slot="table"
              ref="refMigrationsList"
              striped
              hover
              :items="myProvider"
              :fields="visibleFields"
              :current-page="paginate.currentPage"
              :per-page="paginate.perPage"
              :busy.sync="isBusy"
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              show-empty
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>
              <template #cell(lead_name)="data">
                <div class="cursor-pointer text-primary">
                  <a
                    class="cursor-pointer"
                    @click="openEditLead(data.item.lead_id)"
                    >{{ data.item.lead_name }}</a
                  >
                </div>

                <div>{{ data.item.mobile }}</div>
              </template>

              <template #cell(account)="data">
                <div>
                  <ul style="padding-left: 0px; margin-bottom: 0px">
                    <li
                      v-for="(account, index) in JSON.parse(data.item.accounts)"
                      :key="index"
                      style="list-style: none"
                    >
                      <router-link
                        :to="{
                          name: 'administration-dashboard',
                          params: {
                            idClient: account.account_id,
                          },
                        }"
                        target="_blank"
                      >
                        {{ account.account }}</router-link
                      >
                    </li>
                  </ul>
                </div>
              </template>

              <template #cell(status)="data">
                <li
                  v-for="(account, index) in JSON.parse(data.item.accounts)"
                  :key="index"
                  style="list-style: none"
                >
                  <status-account
                    :account="account"
                    :text="true"
                  ></status-account>
                </li>
                <div></div>
              </template>

              <template #cell(advisor_name)="data">
                <li
                  v-for="(account, index) in JSON.parse(data.item.accounts)"
                  :key="index"
                  style="list-style: none"
                >
                  {{ account.advisor_name }}
                </li>
              </template>

              <template #cell(creation_date)="data">
                <li
                  v-for="(account, index) in JSON.parse(data.item.accounts)"
                  :key="index"
                  style="list-style: none"
                >
                  <span v-if="account.created_at != null">{{
                    account.created_at | myDate
                  }}</span>
                  <span v-else>-</span> |
                  {{ account.user_name != null ? account.user_name : "-" }}
                </li>
              </template>

              <template #cell(finish)="data">
                <li
                  v-for="(account, index) in JSON.parse(data.item.accounts)"
                  :key="index"
                  style="list-style: none"
                >
                  <span
                    :style="
                      account.migrating == 0 ? 'color:#00CC00' : 'color:#FF0000'
                    "
                    >{{ account.migrating == 1 ? "NO" : "YES" }}</span
                  >
                </li>
              </template>

              <template #cell(finish_date)="data">
                <li
                  v-for="(account, index) in JSON.parse(data.item.accounts)"
                  :key="index"
                  style="list-style: none"
                >
                  <span v-if="account.updated_at != null">{{
                    account.updated_at | myDate
                  }}</span>
                  <span v-else>-</span> |
                  {{
                    account.updater_name != null ? account.updater_name : "-"
                  }}
                </li>
              </template>

              <template #cell(actions)="data">
                <div class="d-flex justify-content-center">
                  <li
                    v-for="(account, index) in JSON.parse(data.item.accounts)"
                    :key="index"
                    style="list-style: none"
                  >
                    <feather-icon
                      v-if="account.migrating == 1"
                      class="text-danger"
                      size="20"
                      icon="PlusCircleIcon"
                      @click="deleteAccount(account.account_id)"
                    />
                  </li>
                </div>
              </template>
            </b-table>
          </template>
        </filter-slot>
      </div>
    </div>
    <create-migration
      v-if="createMigrationOn"
      :lead_id="lead_id"
      @refresh="$refs.refMigrationsList.refresh()"
      @close="closeCreateMigration"
    />

    <lead-update
      :is-add-new-user-sidebar-active.sync="isAddUpdateUserSidebarActive"
      :lead="S_LEAD_EDIT"
      :type-edit="typeEdit"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import MigrationsService from "@/views/administration/views/migrations/service/migrations.service";
import CreateMigration from "@/views/administration/views/migrations/CreateMigration.vue";
import PaymentService from "@/views/crm/views/payments/service/payments.service";
import LeadUpdate from "@/views/crm/views/Lead/lead-module/save/LeadUpdate.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

export default {
  components: {
    CreateMigration,
    LeadUpdate,
    StatusAccount,
  },

  data() {
    return {
      isAddUpdateUserSidebarActive: false,
      editSidebar: "first",
      typeEdit: "lead",
      createMigrationOn: false,
      isSortDirDesc: true,
      sortBy: "",
      isBusy: false,
      items: [],
      fields: [
        {
          key: "lead_name",
          label: "Name",
          sortable: true,
          visible: true,
        },
        {
          key: "mobile",
          label: "Mobile",
          sortable: false,
          visible: false,
        },
        {
          key: "account",
          label: "Account",
          sortable: true,
          visible: true,
        },
        {
          key: "program",
          label: "Program",
          sortable: false,
          visible: false,
        },
        {
          key: "status",
          label: "Status",
          sortable: false,
          visible: true,
          thStyle: { width: "230px" },
        },
        {
          key: "advisor_name",
          label: "Advisor",
          sortable: false,
          visible: true,
        },
        {
          key: "creation_date",
          label: "Creation Date | User Create",
          sortable: true,
          visible: true,
        },

        {
          key: "finish",
          label: "Finish",
          sortable: false,
          visible: true,
        },
        {
          key: "finish_date",
          label: "Finish Date | User Finish",
          sortable: false,
          visible: true,
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: { textAlign: "center" },
          visible: true,
        },
      ],
      filters: [
        {
          type: "datepicker",
          margin: true,
          showLabel: true,
          label: "From",
          placeholder: "Date",
          class: "font-small-3",
          model: null,
          locale: "en",
          dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          },
          cols: 6,
        },
        {
          type: "datepicker",
          margin: true,
          showLabel: true,
          label: "To",
          placeholder: "Date",
          class: "font-small-3",
          model: null,
          locale: "en",
          dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          },
          cols: 6,
        },
        {
          type: "select",
          margin: true,
          showLabel: true,
          label: "Programs",
          model: null,
          options: [],
          reduce: "value",
          selectText: "label",
          cols: 12,
        },
        {
          type: "select",
          margin: true,
          showLabel: true,
          label: "Advisor",
          model: null,
          options: [],
          reduce: "value",
          selectText: "label",
          cols: 12,
        },
        {
          type: "select",
          margin: true,
          showLabel: true,
          label: "Status",
          model: null,
          options: [],
          reduce: "value",
          selectText: "label",
          cols: 12,
        },
      ],
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: 0,
      toPage: 0,
      totalRows: 0,
      nextPage: null,
      endPage: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    ...mapState({
      S_LEAD_EDIT: (state) => state.CrmLeadStore.S_LEAD_EDIT,
    }),
  },
  methods: {
    ...mapActions({
      A_GET_LEAD_EDIT: "CrmLeadStore/A_GET_LEAD_EDIT",
      A_GET_PROGRAMS: "CrmGlobalStore/A_GET_PROGRAMS",
      A_GET_STATE_LEADS: "CrmLeadStore/A_GET_STATE_LEADS",
      A_GET_STATUS_LEADS: "CrmLeadStore/A_GET_STATUS_LEADS",
      A_GET_SOURCE_LEADS: "CrmLeadStore/A_GET_SOURCE_LEADS",
      A_GET_SOURCE_NAMES: "CrmGlobalStore/A_GET_SOURCE_NAMES",
      A_GET_STATES: "CrmGlobalStore/A_GET_STATES",
      A_GET_EEUU_STATES: "CrmGlobalStore/A_GET_EEUU_STATES",
      A_GET_COUNTRIES: "CrmGlobalStore/A_GET_COUNTRIES",
      A_GET_OWNERS: "CrmGlobalStore/A_GET_OWNERS",
    }),

    async getPrograms() {
      try {
        await this.A_GET_PROGRAMS();
      } catch (error) {
        console.log("Something went wrong getPrograms", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStateLeads() {
      try {
        await this.A_GET_STATE_LEADS();
      } catch (error) {
        console.log("Something went wrong getStateLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStatusLeads() {
      try {
        await this.A_GET_STATUS_LEADS();
      } catch (error) {
        console.log("Something went wrong getStatusLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceLeads() {
      try {
        await this.A_GET_SOURCE_LEADS();
      } catch (error) {
        console.log("Something went wrong getSourceLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceNames() {
      try {
        await this.A_GET_SOURCE_NAMES();
      } catch (error) {
        console.log("Something went wrong getSourceNames:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStates() {
      try {
        await this.A_GET_STATES({ type: 1 });
      } catch (error) {
        console.log("Something went wrong getStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getEeuuStates() {
      try {
        await this.A_GET_EEUU_STATES();
      } catch (error) {
        console.log("Something went wrong getEeuuStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getCountries() {
      try {
        await this.A_GET_COUNTRIES();
      } catch (error) {
        console.log("Something went wrong getCountries:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },

    async getOwners() {
      try {
        const roles = [2, 4].includes(this.moduleId) ? "[1,2,5]" : "[1,2,3,5]";
        await this.A_GET_OWNERS({
          modul: this.moduleId,
          body: { roles, type: "1" },
        });
      } catch (error) {
        console.log("Something went wrong getOwners:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getLeadEdit(leadId) {
      try {
        await this.A_GET_LEAD_EDIT({ id: leadId });
      } catch (error) {
        console.log("Something went wrong getLeadEdit", error);
      }
    },

    async openEditLead(leadId) {
      this.isAddUpdateUserSidebarActive = false;
      this.addPreloader();
      await Promise.all([
        this.getLeadEdit(leadId),
        this.getPrograms(),
        this.getStateLeads(),
        this.getStatusLeads(),
        this.getSourceLeads(),
        this.getSourceNames(),
        this.getStates(),
        this.getEeuuStates(),
        this.getCountries(),
        this.getOwners(),
      ]);
      this.editSidebar = "second";
      this.removePreloader();
      this.isAddUpdateUserSidebarActive = true;
    },

    async refresh() {
      this.addPreloader();
      const data = await this.$store.dispatch(
        "DebtSolutionClients/A_GET_CLIENTS",
        {
          id: this.$route.params.idClient,
        }
      );
      this.removePreloader();
    },

    closeCreateMigration() {
      this.createMigrationOn = false;
    },
    openCreateMigration() {
      this.createMigrationOn = true;
    },

    async deleteAccount(accountId) {
      try {
        const confirm = await this.showConfirmSwal();
        if (confirm.value) {
          const params = {
            id: accountId,
          };
          const response = await MigrationsService.deleteAccount(params);
          if (response.status === 200) {
            this.showSuccessSwal("Account deleted successfully");

            this.$refs.refMigrationsList.refresh();
          }
        }
      } catch (error) {
        this.showErrorSwal("Error deleting account");
      }
    },

    async myProvider(ctx) {
      let sortby = 5;
      let sortDirection = "desc";
      if (ctx.sortBy === "lead_name") {
        sortby = 2;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "account") {
        sortby = 4;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "creation_date") {
        sortby = 5;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      }

      try {
        const params = {
          text: this.filterPrincipal.model,
          from: this.filters[0].model,
          to: this.filters[1].model,
          program: this.filters[2].model,
          orderby: sortby,
          order: sortDirection,
          status: this.filters[4].model,
          advisor: this.filters[3].model,
          perpage: ctx.perPage,
          page: ctx.currentPage,
        };
        const res = await MigrationsService.searchClientMigration(params);
        this.items = res.data;

        this.startPage = res.from;
        this.paginate.currentPage = res.current_page;
        this.paginate.perPage = res.per_page;
        this.totalRows = res.total;
        this.toPage = res.to;
        this.nextPage = this.startPage + 1;
        this.endPage = res.last_page;

        return this.items || [];
      } catch (error) {
        this.isBusy = false;

        console.log(error);
        return [];
      }
    },
  },
};
</script>
