<template>
  <b-modal
    v-model="ownModal"
    title="MIGRATION - PROCESS"

    size="lg"
    @hidden="close"
  >
    <div>
      <b-container fluid>
        <b-row>
          <b-col md="3">
            <label>Search Leads | Clients</label>
          </b-col>

          <b-col md="9">
            <vue-autosuggest

              ref="autocomplete"
              v-model="userfilter"
              :suggestions="filteredOptions"
              :get-suggestion-value="getSuggestionValue"
              :limit="20"
              :input-props="{id:'autosuggest__input',class:'form-control ', placeholder:'Select'}"

              @input="searchLeads"
              @selected="selectHandler"
            >
              <template slot-scope="{ suggestion }">
                <span class="my-suggestion-item">
                  {{ suggestion.item.lead_name }}
                </span>
              </template>
            </vue-autosuggest>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col sm="4">
            <validation-provider
              v-slot="{ errors }"
              name="Lead"
              rules="required"
            >
              <b-form-group
                label="Lead"
                label-class="font-weight-bolder"
              >
                <b-form-input

                  v-model="nameLead"
                  :disabled="true"
                  :class="{ 'border-danger': errors[0] }"
                />
                <span
                  v-if="errors[0]"
                  class="text-danger"
                >
                  Lead {{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="4">
            <validation-provider
              v-slot="{ errors }"
              name="Mobile"
              rules="required"
            >
              <b-form-group
                label="Mobile"
                label-class="font-weight-bolder"
              >
                <b-form-input

                  v-model="mobileLead"
                  :disabled="true"
                  :class="{ 'border-danger': errors[0] }"
                />
                <span
                  v-if="errors[0]"
                  class="text-danger"
                >
                  Mobile {{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="4">
            <validation-provider
              v-slot="{ errors }"
              name="Programs"
              rules="required"
            >
              <b-form-group
                label="Programs"
                label-class="font-weight-bolder"
              >
                <ul>
                  <li
                    v-for="(item, index) in programs"
                    :key="index"
                  >
                    {{ item.program_name+' || '+item.account }}
                  </li>
                </ul>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="4">
            <validation-provider
              v-slot="{ errors }"
              name="Account"
              rules="required"
            >
              <b-form-group
                label="Account"
                label-class="font-weight-bolder"
              >
                <b-form-input

                  v-model="account"
                  :class="{ 'border-danger': errors[0] }"
                />
                <span
                  v-if="errors[0]"
                  class="text-danger"
                >
                  Account {{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="4">
            <validation-provider
              v-slot="{ errors }"
              name="Programs"
              rules="required"
            >
              <b-form-group
                label="Programs"
                label-class="font-weight-bolder"
              >
                <b-select
                  v-model="program"
                  :options="programsAll"
                  value-field="id"
                  text-field="value"
                />
                <span
                  v-if="errors[0]"
                  class="text-danger"
                >
                  Programs {{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="4">
            <validation-provider
              v-slot="{ errors }"
              name="Advisors"
              rules="required"
            >
              <b-form-group
                label="Advisors"
                label-class="font-weight-bolder"
              >
                <b-select
                  v-model="program"
                  :options="programsAll"
                  value-field="id"
                  text-field="value"
                />
                <span
                  v-if="errors[0]"
                  class="text-danger"
                >
                  Advisors {{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="4">
            <validation-provider
              v-slot="{ errors }"
              name="Status"
              rules="required"
            >
              <b-form-group
                label="Status"
                label-class="font-weight-bolder"
              >
                <b-form-input

                  v-model="nameLead"
                  :disabled="true"
                  :class="{ 'border-danger': errors[0] }"
                />
                <span
                  v-if="errors[0]"
                  class="text-danger"
                >
                  Status {{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="4">
            <validation-provider
              v-slot="{ errors }"
              name="Star Date"
              rules="required"
            >
              <b-form-group
                label="Star Date"
                label-class="font-weight-bolder"
              >
                <b-form-input

                  v-model="nameLead"
                  :disabled="true"
                  :class="{ 'border-danger': errors[0] }"
                />
                <span
                  v-if="errors[0]"
                  class="text-danger"
                >
                  Star Date{{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="4">
            <validation-provider
              v-slot="{ errors }"
              name="Seller"
              rules="required"
            >
              <b-form-group
                label="Seller"
                label-class="font-weight-bolder"
              >
                <b-form-input

                  v-model="nameLead"
                  :disabled="true"
                  :class="{ 'border-danger': errors[0] }"
                />
                <span
                  v-if="errors[0]"
                  class="text-danger"
                >
                  Seller {{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="4">
            <validation-provider
              v-slot="{ errors }"
              name="Capture"
              rules="required"
            >
              <b-form-group
                label="Seller"
                label-class="font-weight-bolder"
              >
                <b-form-input

                  v-model="nameLead"
                  :disabled="true"
                  :class="{ 'border-danger': errors[0] }"
                />
                <span
                  v-if="errors[0]"
                  class="text-danger"
                >
                  Capture {{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <template #modal-footer>
      <b-button
        :disabled="spinner"
        variant="info"
        class="rounded"
        @click="generateTransaction()"
      >Save<b-spinner
        v-if="spinner"
        class="ml-1"
        small
      /> </b-button>
    </template>
  </b-modal>
</template>

<script>

import { mapGetters } from 'vuex'
import { VueAutosuggest } from 'vue-autosuggest'
import InitialPaymentService from '@/views/administration/views/initial-payment/service/initial-payment.service'
import VSelect from 'vue-select'
import MigrationsService from '@/views/administration/views/migrations/service/migrations.service'
import ClientService from '@/views/commons/components/clients/services/clients.services'

export default {
  components: {
    VueAutosuggest,
    VSelect,
  },
  props: {
    lead_id: {
      type: Number,

    },
  },

  data() {
    return {
      advisors: [],
      mobile: '',
      lead: '',
      programs: [],
      account: '',
      user_id: '',
      program: '',
      programsAll: [],
      userfilter: '',
      filteredOptions: [],

      spinner: false,
      ownModal: true,
      transaction_id: '',
      amount: 0,

    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    nameLead() {
      return this.userfilter.substring(0, this.userfilter.indexOf('|'))
    },

    mobileLead() {
      return this.userfilter.substring(this.userfilter.indexOf('|') + 2)
    },

  },

  methods: {
    async usersPrograms() {
      try {
        const params = {
          idmodule: this.moduleId,
          iduser: this.currentUser.user_id,
          idrole: this.currentUser.role_id,
        }
        if (this.currentUser.role_id) {
          const data = await ClientService.usersPrograms(params)
          this.filters[2].label = 'Advisor'
          this.filters[2].selectText = 'user_name'
          this.filters[2].options = data
        }
      } catch (error) {
        console.log(error)
      }
    },

    async searchPrograms() {
      try {
        const response = await ClientService.searchClientPayment({
          id: this.user_id,
        })
        if (response.status == 200) {
          this.programs = response.data
          if (this.programs.length > 0) {
            this.lead = this.programs[0].lead_name
            this.mobile = this.programs[0].mobile
          } else {
            this.lead = ''
            this.mobile = ''
          }
          const data = await ClientService.programs()
          this.programsAll = data.data
          this.programs.sort((a, b) => a.program_id - b.program_id)
          for (let i = 0; i < this.programsAll.length; i++) {
            for (let y = 0; y < programs.length; y++) {
              if (this.programsAll[i].id == this.programs[y].program_id) {
                this.programsAll.splice(i, 1)
              }
            }
          }
          const sper = this.currentUser.sale_permission
          const spn = []
          if (sper.length != this.programsAll.length) {
            sper.map(spe => {
              this.programsAll.map(pro => {
                if (spe.id == pro.id) {
                  spn.push(pro)
                }
              })
            })
            this.programsAll = spn
          }
        }
      } catch (error) {
        console.log(error)
      }
    },

    async searchLeads(text) {
      this.userfilter = ''

      if (text === '' || text === undefined) {
        this.filteredOptions = []
        return
      }

      try {
        const data = await MigrationsService.searchClient({ q: text })
        this.filteredOptions = [{ data }]
      } catch (error) {
        console.error(error)
        this.showToast(
          'danger',
          'top-right',
          'Error',
          'XIcon',
          'Something went wrong!',
        )
      }
    },
    async selectHandler(value) {
      this.user_id = value.item.id
      this.userfilter = `${value.item.lead_name}`

      this.users = null
      await this.searchPrograms()
      this.statusSelected = false
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.value
    },
    async generateTransaction() {
      try {
        const params = {
          lead_id: this.lead_id,
          transaction_id: this.transaction_id,
          amount: this.amount,
          id: this.initialPaymentId,
        }
        this.spinner = true
        const response = await InitialPaymentService.generateTransaction(params)

        if (response.status === 500) {
          this.spinner = false

          this.$swal({
            title: 'Warning',
            text: response.message,
            icon: 'warning',
            timer: 2000,
            showConfirmButton: false,
          })

          this.$emit('refresh')
          this.close()
        }
        this.spinner = false
        this.$emit('refresh')
        this.close()
      } catch (error) {
        this.spinner = true
        console.log('error', error)
        this.$swal({
          title: 'Error',
          text: 'Error creating relationship',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
        })
      }
    },

    close() {
      this.$emit('close')
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";

</style>
